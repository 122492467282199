import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { cilCheckCircle, cilLockLocked } from '@coreui/icons';
import { Subscription } from 'rxjs';
import { CustomValidators } from '../../validators/custom-validators';

@Component({
  selector: 'app-password-meter-progressbar',
  templateUrl: './password-meter-progressbar.component.html',
  styleUrls: ['./password-meter-progressbar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordMeterProgressbarComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PasswordMeterProgressbarComponent,
    },
  ],
})
export class PasswordMeterProgressbarComponent
  implements ControlValueAccessor, Validator
{
  score: number = 0;
  progressBarColor: string = '';
  validationForm: FormGroup;
  icons = { cilCheckCircle, cilLockLocked };

  onTouched: Function = () => {};
  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder) {
    this.validationForm = this.fb.group(
      {
        password: [
          null,
          [
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              { hasSpecialCharacters: true }
            ),
            // 6. Has a minimum length of 10 characters
            Validators.minLength(10),
          ],
        ],
        confirmPassword: [null, Validators.required],
      },
      {
        validators: CustomValidators.matchingPassword(
          'password',
          'confirmPassword'
        ),
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.map((subscribe) => subscribe.unsubscribe());
  }

  // implementation of ControlValueAccessor methods
  writeValue(obj: any): void {
    // Get the value from Parent Form
    if (obj) {
      this.validationForm.patchValue({ password: obj });
    }
  }
  registerOnChange(onChange: any): void {
    // The child control notify the parent form that a new value is available
    const sub = this.validationForm.valueChanges.subscribe(onChange);
    this.subscriptions.push(sub);
  }
  registerOnTouched(onTouched: any): void {
    // the child control inform the parent form that the child is touched
    this.onTouched = onTouched;
  }


  // This method is used to validate the current value of the form control.
  validate() {
    if (this.validationForm.valid) {
      return null;
    }
    return ({passwordNotValid: true});
  }

  onChangePassword(value: string): void {
    this.score = 0;
    if (value !== '') {
      this.score += this.validationForm.controls['password'].hasError(
        'minlength'
      )
        ? 0
        : 20;
      this.score += this.validationForm.controls['password'].hasError(
        'hasSpecialCharacters'
      )
        ? 0
        : 20;
      this.score += this.validationForm.controls['password'].hasError(
        'hasNumber'
      )
        ? 0
        : 20;
      this.score += this.validationForm.controls['password'].hasError(
        'hasCapitalCase'
      )
        ? 0
        : 20;
      this.score += this.validationForm.controls['password'].hasError(
        'hasSmallCase'
      )
        ? 0
        : 20;
    }
    if (this.score < 50) this.progressBarColor = 'danger';
    else if (this.score <= 80 && this.score >= 50)
      this.progressBarColor = 'warning';
    else this.progressBarColor = 'success';
  }
}
