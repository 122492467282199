export enum RoleEnum {
  ROOT = 'ROOT',
  CLIENT = 'CLIENT',
  SELLER = 'SELLER',
  DELIVERY_MAN = 'DELIVERY_MAN',
  WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER',
  WAREHOUSE_CONTROLLER = 'WAREHOUSE_CONTROLLER',
  SUPPORT_AGENT = 'SUPPORT_AGENT',
  DELIVERY_SUPERVISOR = 'DELIVERY_SUPERVISOR',
  WAREHOUSE_PREPARER = 'WAREHOUSE_PREPARER',
  WAREHOUSE_SUPERVISOR = 'WAREHOUSE_SUPERVISOR',
}
