<c-container>
  <c-row class="mb-2">
    <c-col [md]="10" [lg]="10" class="mx-auto">
      <form [formGroup]="validationForm">
        <h6 class="text-medium-emphasis">
          {{ "Mot de passe" | uppercase }}
        </h6>
        <c-input-group class="mb-3">
          <span cInputGroupText>
            <svg [cIcon]="icons.cilLockLocked" class="primary-icon"></svg>
          </span>
          <input
            type="password"
            cFormControl
            formControlName="password"
            (ngModelChange)="onChangePassword($event)"
          />
        </c-input-group>
        <h6 class="text-medium-emphasis">
          {{ "Confirmez le mot de passe" | uppercase }}
        </h6>
        <c-input-group class="mb-3">
          <span cInputGroupText>
            <svg [cIcon]="icons.cilLockLocked" class="primary-icon"></svg>
          </span>
          <input
            type="password"
            cFormControl
            formControlName="confirmPassword"
          />
          <c-form-feedback
            [valid]="false"
            *ngIf="
              (validationForm.get('confirmPassword')?.dirty ||
                validationForm.get('confirmPassword')?.touched) &&
              validationForm.hasError('mismatch')
            "
          >
            La valeur saisie ne correspond pas au mot de passe
          </c-form-feedback>
        </c-input-group>
      </form>
      <ng-content></ng-content>
    </c-col>
  </c-row>

  <!--Messages related to password expectations-->
  <c-row class="mt-4" *ngIf="validationForm.value.password">
    <!--Password meter progressbar-->
    <c-row class="mb-3">
      <c-col [md]="8" [lg]="8" class="mx-auto">
        <c-progress [height]="8">
          <c-progress-bar
            [animated]="true"
            [color]="progressBarColor"
            [value]="score"
            variant="striped"
          ></c-progress-bar>
        </c-progress>
      </c-col>
    </c-row>
    <c-col [md]="10" [lg]="10" class="mx-auto">
      <ul class="text-center">
        <li>
          <svg
            [cIcon]="icons.cilCheckCircle"
            size="s"
            [ngClass]="
              validationForm.controls['password'].hasError('minlength')
                ? 'text-dark'
                : 'text-success'
            "
          ></svg>
          Votre mot de passe doit comporter au moins 10 caractères
        </li>
        <li>
          <svg
            [cIcon]="icons.cilCheckCircle"
            size="s"
            [ngClass]="
              validationForm.controls['password'].hasError(
                'hasSpecialCharacters'
              )
                ? 'text-dark'
                : 'text-success'
            "
          ></svg>
          Votre mot de passe doit inclure au moins 1 caractère spécial
        </li>
        <li>
          <svg
            [cIcon]="icons.cilCheckCircle"
            size="s"
            [ngClass]="
              validationForm.controls['password'].hasError('hasNumber')
                ? 'text-dark'
                : 'text-success'
            "
          ></svg>
          Votre mot de passe doit inclure au moins 1 chiffre
        </li>
        <li>
          <svg
            [cIcon]="icons.cilCheckCircle"
            size="s"
            [ngClass]="
              validationForm.controls['password'].hasError('hasCapitalCase')
                ? 'text-dark'
                : 'text-success'
            "
          ></svg>
          Votre mot de passe doit inclure au moins 1 majuscule
        </li>
        <li>
          <svg
            [cIcon]="icons.cilCheckCircle"
            size="s"
            [ngClass]="
              validationForm.controls['password'].hasError('hasSmallCase')
                ? 'text-dark'
                : 'text-success'
            "
          ></svg>
          Votre mot de passe doit inclure au moins 1 minuscule
        </li>
      </ul>
    </c-col>
  </c-row>
</c-container>
