import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);
        if (!valid) {
          return error;
        }
      }
      return null;
    };
  }

  static matchingPassword(source: string, target: string): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);
      if (sourceCtrl?.value !== targetCtrl?.value) {
        return {mismatch: true}
      }
      return null;
    };
  }

}
