export const commonProperties = {
  //products
  productList: 'matjar/productMgtApi/products',
  productById: 'matjar/productMgtApi/products/:productId',
  packageList: 'matjar/productMgtApi/products/:productId/packages',
  packageById: 'matjar/productMgtApi/products/:productId/packages/:packageId',
  productImgByProductId: 'matjar/productMgtApi/products/:productId/image',
  productImgThumbnailByProductId:
    'matjar/productMgtApi/products/:productId/image',
  productPackingImageById:
    'matjar/productMgtApi/products/:productId/packages/:packageId/images/:productPackingImageId',
  productPackingImages:
    'matjar/productMgtApi/products/:productId/packages/:packageId/images/',
  productExport: 'matjar/productMgtApi/products/export',
  productImport: 'matjar/productMgtApi/products/import',
  productValidateFile: 'matjar/productMgtApi/products/validate-file',
  importAndBindImage: 'matjar/productMgtApi/products/upload-image',
  anomalyList:
    'matjar/orderMgtApi/orders/:orderId/items/:orderItemId/anomalies',
  anomalyImage:
    'matjar/orderMgtApi/orders/:orderId/items/:orderItemId/anomalies/:anomalyItemId/image',

  //categories
  categoryList: 'matjar/productMgtApi/categories',
  categoryById: 'matjar/productMgtApi/categories/:categoryId',
  categoryExport: 'matjar/productMgtApi/categories/export',
  categoryImport: 'matjar/productMgtApi/categories/import',
  categoryValidateFile: 'matjar/productMgtApi/categories/validate-file',

  //families
  familyList: 'matjar/productMgtApi/families',
  familyById: 'matjar/productMgtApi/families/:familyId',

  //package names
  packageNameList: 'matjar/productMgtApi/packageNames',
  packageNameById: 'matjar/productMgtApi/packageNames/:packageNameId',

  //atomic-units
  atomicUnitList: 'matjar/productMgtApi/atomic-units',
  atomicUnitById: 'matjar/productMgtApi/atomic-units/:unitId',
  atomicUnitExport: 'matjar/productMgtApi/atomic-units/export',
  atomicUnitImport: 'matjar/productMgtApi/atomic-units/import',
  atomicUnitValidateFile: 'matjar/productMgtApi/atomic-units/validate-file',

  //packing-units
  packingUnitList: 'matjar/productMgtApi/packing-units',
  packingUnitById: 'matjar/productMgtApi/packing-units/:unitId',
  packingUnitExport: 'matjar/productMgtApi/packing-units/export',
  packingUnitImport: 'matjar/productMgtApi/packing-units/import',
  packingUnitValidateFile: 'matjar/productMgtApi/packing-units/validate-file',

  //brands
  brand: 'matjar/productMgtApi/brands',
  brandList: 'matjar/productMgtApi/brands',
  brandById: 'matjar/productMgtApi/brands/:brandId',

  //authentication
  login: 'authMgtApi/authenticate',
  forgotPassword: 'authMgtApi/forgot-password',
  resetPassword: 'authMgtApi/reset-password',
  validateAccount: 'authMgtApi/activate-account',
  tokenValidation: 'authMgtApi//validate-token',

  //suppliers
  supplierList: 'supplierMgtApi/suppliers',

  //warehouses
  warehouseList: 'matjar/warehouseMgtApi/warehouses',
  warehouseById: 'matjar/warehouseMgtApi/warehouses/:warehouseId',

  //assets
  assetsList: 'matjar/assetMgtApi',
  assetById: 'matjar/assetMgtApi/:assetId',

  //warehouse assets
  warehouseAssets: 'matjar/warehouseMgtApi/warehouses/:warehouseId/assets',

  //csv template
  importTemplate: 'matjar/templates',

  //warehouse items
  warehouseItems: 'matjar/warehouseMgtApi/warehouses/:warehouseId/items',
  warehouseStockExport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/items/export',
  supplyImport: 'matjar/warehouseMgtApi/warehouses/:warehouseId/supply-import',
  supplyImportValidate:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/supply-validate',
  dailyProductOutOfStockKpi: 'matjar/kpis/daily-products-out-of-stock',
  dailyPowerProductsByAmount: 'matjar/kpis/daily-power-products-by-amount',
  dailyPowerProductsByQuantity: 'matjar/kpis/daily-power-products-by-quantity',
  productsLocationImport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/items/import-location',
  productsLocationImportValidate:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/items/import-location-validate-file',
  //warehouse movements
  warehouseMovements:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/movements',
  warehouseManagers: 'matjar/warehouseMgtApi/warehouses/:warehouseId/managers',
  warehouseManagersById:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/managers/:managerId',
  warehouseControllers:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/controllers',
  warehouseControllersById:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/controllers/:controllerId',
  warehouseMovementsExport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/movements/export',
  // Warehouse intra movements
  warehouseIntraMovements:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/intra-movements',
  warehouseIntraMovementsExport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/intra-movements/export',
  warehouseIntraMovementsValidateFile:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/intra-movements/validate-file',
  warehouseIntraMovementsImport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/intra-movements/import',
  //warehouse batch config
  warehouseBatchConfigs:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/job-config',
  //warehouse rolling inventory movements
  warehouseRollingInventoryMovements:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/rolling-inventories/movements',
  warehouseRollingInventoryMovementsValidateFile:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/rolling-inventories/validate-file',
  warehouseRollingInventoryMovementsImport:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/rolling-inventories/import',

  // teams
  warehosueTeams: 'matjar/warehouseMgtApi/warehouses/:warehosueId/teams',
  warehosueTeam: 'matjar/warehouseMgtApi/warehouses/:warehosueId/teams/:teamId',
  warehouseTeamManager:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/teams/:teamId/managers',
  warehouseTeamController:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/teams/:teamId/controllers',
  warehouseTeamPreparer:
    'matjar/warehouseMgtApi/warehouses/:warehouseId/teams/:teamId/preparers',

  //ipo
  ipoList: 'procurementMgtApi/incomingPurchaseOrders',
  ipoById: 'procurementMgtApi/incomingPurchaseOrders/:ipoId',
  ipoItems: 'procurementMgtApi/incomingPurchaseOrders/:ipoId/items',
  ipoItemById:
    'procurementMgtApi/incomingPurchaseOrders/:ipoId/items/:ipoItemId',
  ipoAdditionalChargeItems:
    'procurementMgtApi/incomingPurchaseOrders/:ipoId/additionalCharges',
  ipoAdditionalChargeItemById:
    'procurementMgtApi/incomingPurchaseOrders/:ipoId/additionalCharges/:ipoAdditionalChargeId',

  //users
  userList: 'authMgtApi/users',
  userById: 'authMgtApi/users/:userId',
  userImageById: 'authMgtApi/users/:userId/image',
  roleList: 'authMgtApi/roles',
  otpKeys: 'notificationMgtApi/messages',
  otpKeysExport: 'notificationMgtApi/messages/export',
  userExport: 'authMgtApi/users/export',
  userForDispatch: 'authMgtApi/users/available-for-dispatch',
  clientsKpi: 'authMgtApi/kpis/clients',
  clientsByCreationKpi: 'authMgtApi/kpis/clients-creation',
  storeByUserId: 'authMgtApi/users/:userId/store',
  createStoreByUserId: 'authMgtApi/users/:userId/store',
  patchStoreByIds: 'authMgtApi/users/:userId/store/:storeId',
  userListByIds: 'authMgtApi/users/get-set-of-users',
  usersImageBase64: 'authMgtApi/users/:userId/image',

  //delivery-agents
  deliveryagents: 'authMgtApi/users/:userId/delivery-agents',
  deliveryagentsById:
    'authMgtApi/users/:userId/delivery-agents/:deliveryAgentId',

  // Receptions
  receptionList: 'receptionMgtApi/receptions',
  receptionById: 'receptionMgtApi/receptions/:receptionOrderId',
  receptionItems: 'receptionMgtApi/receptions/:orderReceptionId/items',
  receptionAdditionalChargeItems:
    'receptionMgtApi/reception/:orderReceptionId/additionalCharges',
  receptionItemById:
    'receptionMgtApi/receptions/:orderReceptionId/items/:orderReceptionItemId',
  receptionAdditionalChargeItemById:
    'receptionMgtApi/reception/:orderReceptionId/additionalCharges/:additionalChargeItemId',

  // invoice
  invoiceList: 'invoiceMgtApi/invoices',
  invoiceById: 'invoiceMgtApi/invoices/:invoiceId',
  invoiceByStatus: 'invoiceMgtApi/invoices/invoice-status',
  invoiceItems: 'invoiceMgtApi/invoices/:invoiceId/items',
  invoiceAdditionalChargeItems:
    'invoiceMgtApi/invoices/:invoiceId/additionalChargeItems',
  invoiceIroAdditionalChargeItemById:
    'invoiceMgtApi/invoices/:invoiceId/iroAdditionalCharge/:additionalChargeItemId',
  invoiceIpoAdditionalChargeItemById:
    'invoiceMgtApi/invoices/:invoiceId/ipoAdditionalCharge/:additionalChargeItemId',

  // payment
  paymentList: 'invoiceMgtApi/invoices/duepayment',
  deletePaymentList: 'invoiceMgtApi/invoices/duepayment/:paymentId',
  paymentsByInvoiceId: 'invoiceMgtApi/invoices/:invoiceId/duepayment',
  PaymentByInvoiceId: 'invoiceMgtApi/invoices/:invoiceId/duepayment/:paymentId',

  // product offering
  productOfferingList: 'productOfferMgtApi/productOffers',
  productOfferById: 'productOfferMgtApi/productOffers/:productOfferId',
  productOfferImageById:
    'productOfferMgtApi/productOffers/:productOfferId/image',

  // product offering itms
  productOfferingItemsList:
    'productOfferMgtApi/productOffers/:productOfferId/items',
  productOfferItemById:
    'productOfferMgtApi/productOffers/:productOfferId/items/:productOfferItemId',
  productOfferItemsImageById:
    'productOfferMgtApi/productOffers/:productOfferId/items/:productOfferItemId/image',

  //prices
  priceList: 'matjar/priceMgtApi/prices',
  priceById: 'matjar/priceMgtApi/prices/:priceId',
  priceExport: 'matjar/priceMgtApi/prices/export',
  priceImport: 'matjar/priceMgtApi/prices/import',
  priceValidateFile: 'matjar/priceMgtApi/prices/validate-file',

  // Orders
  orderTransfer: 'matjar/orderMgtApi/orders/transfer',
  orderList: 'matjar/orderMgtApi/orders',
  orderToDeliverList: 'matjar/orderMgtApi/orders/status/to_deliver',
  orderToDeliverListInvoice:
    'matjar/orderMgtApi/orders/status/to_deliver/invoice',
  orderById: 'matjar/orderMgtApi/orders/:orderId',
  orderExport: 'matjar/orderMgtApi/orders/export',
  ordersToDispatch: 'matjar/orderMgtApi/orders/orders-to-dispatch',
  orderImport: 'matjar/orderMgtApi/orders/import',
  orderValidateFile: 'matjar/orderMgtApi/orders/validate-file',
  orderItems: 'matjar/orderMgtApi/orders/:orderId/items',
  dailySaleKpi: 'matjar/kpis/daily-sales',
  weeklySaleKpi: 'matjar/kpis/weekly-orders-by-channel',
  toDeliverProducts: 'matjar/orderMgtApi/orders/prepared-products',
  historyOrderById: 'matjar/orderMgtApi/orders/:orderId/history',
  regionList: 'matjar/orderMgtApi/regions',
  regionById: 'matjar/orderMgtApi/regions/:regionId',

  // Plan
  plan: 'matjar/corporateMgtApi/plans',
  planList: 'matjar/corporateMgtApi/plans',
  planById: 'matjar/corporateMgtApi/plans/:planId',

  // Visits
  visitList: 'matjar/orderMgtApi/visits',
  visitListExport: 'matjar/orderMgtApi/visits/export',
  dailyVisitsKpi: 'matjar/kpis/daily-visits',

  // Notification
  notificationList: 'notificationMgtApi/notifications',

  // Versions
  versionList: 'versionMgtApi/versions',
  versionById: 'versionMgtApi/versions/:versionId',
  currency: 'MAD',

  // Delivery
  // Cash deposits
  cashDepositList: 'deliveryMgtApi/cashDeposits',
  cashDepositImageById: 'deliveryMgtApi/cashDeposits/:cashDepositId/image',
  cashDepositById: 'deliveryMgtApi/cashDeposits/:cashDepositId',
  cashDepositListExport: 'deliveryMgtApi/cashDeposits/export',
  // Fees
  feeList: 'deliveryMgtApi/fees',
  feeImageById: 'deliveryMgtApi/fees/:feeId/image',
  feeById: 'deliveryMgtApi/fees/:feeId',
  feeExport: 'deliveryMgtApi/fees/export',

  // Tours
  tourList: 'deliveryMgtApi/tours',
  tourById: 'deliveryMgtApi/tours/:tourId',
  tourOrderList: 'deliveryMgtApi/tours/:tourId/orders',
  deliveryAgentsTours: 'deliveryMgtApi/delivery-agents-tours',
  tourLoadById: 'deliveryMgtApi/tours/:tourId/load',
  tourUnloadById: 'deliveryMgtApi/tours/:tourId/unload',
  lastTour: 'deliveryMgtApi/deliveryMans',
  exportLoadVerificationsDetails: 'deliveryMgtApi/tours/:tourId/load/export',
  unassignedWarehouseControllerLoad:
    'deliveryMgtApi/tours/:tourId/load/unassigned-controller',
  unassignedWarehouseControllerUnload:
    'deliveryMgtApi/tours/:tourId/unload/unassigned-controller',

  // Payments Transaction
  transactions: 'referralMgtApi/payments',

  // kpis
  mileageKpiByDeliveryMan: 'deliveryMgtApi/kpis/mileage-by-delivery-man',
  deliveredOrdersAmountByDeliveryMan: 'matjar/kpis/orders-delivered-amount',
  tourByDeliveryId: 'deliveryMgtApi/delivery-man-tour',
  currentDeliveryTrack: 'deliveryMgtApi/deliveryTrack/current',
  ordersCountByStatus: 'matjar/kpis/orders-count-by-tour-delivery-man-status',
  loadControlPercentageKpi: 'deliveryMgtApi/kpis/load-control-error-percentage',
  loadVerificationsPercentageKpi:
    'deliveryMgtApi/kpis/load-verification-row-error-percentage',
  movementTrafficKpi: 'matjar/kpis/movementTrafficAmounts',

  rankedProducts: 'deliveryMgtApi/kpis/ranked-products',
  rankedProductsExport: 'deliveryMgtApi/kpis/ranked-products/export',
  //cash-plus
  // accounts
  accountList: 'cashPlusMgtApi/accounts',
  accountById: 'cashPlusMgtApi/accounts/:accountId',
  assignedAccounts: 'cashPlusMgtApi/accounts/:deliveryAgentId/assignedAccounts',
  assignAccount: 'cashPlusMgtApi/accounts/:accountId/deliveryAgents',
  unassignAccount:
    'cashPlusMgtApi/accounts/:accountId/deliveryAgents/:deliveryAgentId',

  // Issue Tracker
  issuesList: 'issueTrackerMgtApi/issues',
  updateIssueById: 'issueTrackerMgtApi/issues/:issueId',
  issueImage: 'issueTrackerMgtApi/issues/:issueId/image',

  // Cause
  causeList: 'issueTrackerMgtApi/causes',

  // Delivery date configs
  deliveryDateConfig: 'matjar/orderMgtApi/delivery-date-configs',
  deliveryDateConfigById: 'matjar/orderMgtApi/delivery-date-configs/:configId',
  deliveryDateConfigByIdSimulate:
    'matjar/orderMgtApi/delivery-date-configs/:configId/simulate',
  ruleByConfigId: 'matjar/orderMgtApi/delivery-date-configs/:configId/rules',
  ruleById: 'matjar/orderMgtApi/delivery-date-configs/:configId/rules/:ruleId',

  // Wallet Partners
  partnerList: 'walletMgtApi/partners',
  partnerById: 'walletMgtApi/partners/:partnerId',
  partnerImageById: 'walletMgtApi/partners/:partnerId/image',

  // Cart Rules
  cartRuleList: 'matjar/cartRules',
  cartRuleById: 'matjar/cartRules/:ruleId',
};
