import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from "@coreui/icons-angular";
import {
  BadgeModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FormModule,
  GridModule,
  ListGroupModule,
  NavModule,
  OffcanvasModule,
  ProgressModule,
  SharedModule,
  SpinnerModule,
  TableModule,
  TabsModule,
  UtilitiesModule,
} from "@coreui/angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {PageTitleComponent} from './components/page-title/page-title.component';
import {ImageComponent} from './components/image/image.component';
import {PageMenuComponent} from './components/page-menu/page-menu.component';
import {ImageUploadCardComponent} from './components/image-upload-card/image-upload-card.component';
import {RouterModule} from '@angular/router';
import {AvatarModule} from "ngx-avatar";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {StepperItemsComponent} from './components/stepper-items/stepper-items.component';
import {WarehouseSearchSelectComponent} from './components/warehouse-search-select/warehouse-search-select.component';
import {CategorySearchSelectComponent} from './components/category-search-select/category-search-select.component';
import {UnitSearchSelectComponent} from './components/unit-search-select/unit-search-select.component';
import {ProductSearchSelectComponent} from './components/product-search-select/product-search-select.component';
import {PageSubtitleComponent} from './components/page-subtitle/page-subtitle.component';
import {BlankPageComponent} from './components/blank-page/blank-page.component';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {ModalConfirmReceptionComponent} from './components/modal-confirm-reception/modal-confirm-reception.component';
import {ModalModule} from "ngx-bootstrap/modal";
import {UserSearchSelectComponent} from './components/user-search-select/user-search-select.component';
import {
  PasswordMeterProgressbarComponent
} from './components/password-meter-progressbar/password-meter-progressbar.component';
import {ImageListComponent} from './components/image-list/image-list.component';
import {AddressComponent} from './components/address/address.component';
import {GeoPointComponent} from './components/geoPoint/geoPoint.component';
import {LocationComponent} from './components/location/location.component';
import {AssetSearchSelectComponent} from './components/asset-search-select/asset-search-select.component';
import {FamilySearchSelectComponent} from './components/family-search-select/family-search-select.component';
import {PromoPlanifierComponent} from './components/promo-planifier/promo-planifier.component';
import {ListContentComponent} from './components/list-content/list-content.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {AlertComponent} from './components/alert/alert.component';
import {AlertModule} from "ngx-bootstrap/alert";
import {
  CategorySearchMultipleSelectComponent
} from "./components/category-search-multiple-select/category-search-multiple-select.component";
import {UploadCsvModalComponent} from './components/upload-csv-modal/upload-csv-modal.component';
import {OrderStatusPipe} from './pipes/order-status.pipe';
import {DateUtcConverterPipe} from './pipes/date-utc-converter.pipe';
import {RolePipe} from './pipes/role.pipe';
import {PaymentStatusPipe} from './pipes/payment-status.pipe';
import {TransactionStatusPipe} from './pipes/transaction-status.pipe';
import {LoadKpiCardComponent} from './components/load-kpi-card/load-kpi-card.component';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {KpiCardAComponent} from './components/widgets/kpi-card-a/kpi-card-a.component';
import {IssueStatusPipe} from './pipes/issue-status.pipe';
import {OrderSearchSelectComponent} from './components/order-search-select/order-search-select.component';
import {CauseSearchSelectComponent} from './components/cause-search-select/cause-search-select.component';
import {MomentAgoPipePipe} from './pipes/moment-ago.pipe';
import {OrderStatusBadgeComponent} from './components/order-status-badge/order-status-badge.component';
import {HistoryOrderPipe} from './pipes/history-order.pipe';
import {TourStatusBadgeComponent} from './components/tour-status-badge/tour-status-badge.component';
import {TourStatusPipe} from './pipes/tour-status.pipe';
import {PopoverModule} from "ngx-bootstrap/popover";
import { DayOfWeekPipe } from './pipes/day-of-week.pipe';
import { RegionPipe } from './pipes/region.pipe';
import { DeliveryDateConfigSearchSelectComponent } from './components/delivery-date-config-search-select/delivery-date-config-search-select.component';
import {KpiCardComponent} from "./components/kpi-card/kpi-card.component";

@NgModule({
  declarations: [
    PageTitleComponent,
    ImageComponent,
    ImageUploadCardComponent,
    PageMenuComponent,
    StepperItemsComponent,
    WarehouseSearchSelectComponent,
    CategorySearchSelectComponent,
    CategorySearchMultipleSelectComponent,
    UnitSearchSelectComponent,
    UserSearchSelectComponent,
    ProductSearchSelectComponent,
    PageSubtitleComponent,
    BlankPageComponent,
    ModalConfirmComponent,
    UserSearchSelectComponent,
    PasswordMeterProgressbarComponent,
    ImageListComponent,
    ModalConfirmReceptionComponent,
    AddressComponent,
    GeoPointComponent,
    LocationComponent,
    AssetSearchSelectComponent,
    FamilySearchSelectComponent,
    PromoPlanifierComponent,
    ListContentComponent,
    SpinnerComponent,
    AlertComponent,
    UploadCsvModalComponent,
    OrderStatusPipe,
    DateUtcConverterPipe,
    RolePipe,
    PaymentStatusPipe,
    TransactionStatusPipe,
    LoadKpiCardComponent,
    KpiCardAComponent,
    OrderStatusBadgeComponent,
    IssueStatusPipe,
    OrderSearchSelectComponent,
    CauseSearchSelectComponent,
    MomentAgoPipePipe,
    HistoryOrderPipe,
    TourStatusBadgeComponent,
    TourStatusPipe,
    DayOfWeekPipe,
    RegionPipe,
    DeliveryDateConfigSearchSelectComponent,
    KpiCardComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    DropdownModule,
    ButtonModule,
    UtilitiesModule,
    SharedModule,
    ListGroupModule,
    BadgeModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    FormModule,
    ModalModule.forRoot(),
    PaginationModule,
    BsDatepickerModule.forRoot(),
    NavModule,
    TabsModule,
    RouterModule,
    AvatarModule,
    FormModule,
    TableModule,
    OffcanvasModule,
    ProgressModule,
    NgxSpinnerModule,
    AlertModule,
    SpinnerModule,
    ButtonGroupModule,
    BsDropdownModule,
    ButtonGroupModule,
    PopoverModule.forRoot()
  ],
  exports: [
    IconModule,
    DropdownModule,
    ButtonModule,
    UtilitiesModule,
    SharedModule,
    ListGroupModule,
    BadgeModule,
    AvatarModule,
    GridModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    FormModule,
    ModalModule,
    PaginationModule,
    BsDatepickerModule,
    NavModule,
    TabsModule,
    AlertModule,
    PageTitleComponent,
    ImageComponent,
    ImageUploadCardComponent,
    PageMenuComponent,
    FormsModule,
    StepperItemsComponent,
    TableModule,
    OffcanvasModule,
    WarehouseSearchSelectComponent,
    PageSubtitleComponent,
    CategorySearchSelectComponent,
    CategorySearchMultipleSelectComponent,
    UnitSearchSelectComponent,
    UserSearchSelectComponent,
    ProductSearchSelectComponent,
    BlankPageComponent,
    PasswordMeterProgressbarComponent,
    AssetSearchSelectComponent,
    ImageListComponent,
    ModalConfirmReceptionComponent,
    LocationComponent,
    FamilySearchSelectComponent,
    PromoPlanifierComponent,
    ListContentComponent,
    SpinnerComponent,
    AlertComponent,
    SpinnerModule,
    ButtonGroupModule,
    UploadCsvModalComponent,
    OrderStatusPipe,
    DateUtcConverterPipe,
    GeoPointComponent,
    RolePipe,
    PaymentStatusPipe,
    TransactionStatusPipe,
    LoadKpiCardComponent,
    KpiCardAComponent,
    OrderStatusBadgeComponent,
    IssueStatusPipe,
    OrderSearchSelectComponent,
    CauseSearchSelectComponent,
    MomentAgoPipePipe,
    HistoryOrderPipe,
    TourStatusBadgeComponent,
    PopoverModule,
    DayOfWeekPipe,
    RegionPipe,
    DeliveryDateConfigSearchSelectComponent,
    KpiCardComponent
  ]
})
export class AwalSharedModule {
}
