import { INavData } from '@coreui/angular';
import { RoleEnum } from '../../awal-shared/models/role.enum';

export const navItems: INavData[] = [
  {
    name: 'DASHBOARD',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    /* badge: {
      color: 'info',
      text: 'NEW'
    } */
  },
  {
    title: true,
    name: 'CATALOGUE',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'LISTE DES PRIX',
    url: '/catalog/price',
    iconComponent: { name: 'cil-money' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    title: true,
    name: 'MASTR DATA',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'PRODUITS',
    url: '/masterdata/products',
    iconComponent: { name: 'cil-barcode' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Nouveau produit',
        url: '/masterdata/products/create',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Liste des produits',
        url: '/masterdata/products/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Importer les Images des Produits',
        url: '/masterdata/products/images/import',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'UNITÉS',
    url: '/masterdata/units',
    iconComponent: { name: 'cil-balance-scale' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'HFS',
        url: '/masterdata/units/atomic',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Caisse',
        url: '/masterdata/units/packing',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'CATÉGORIE',
    url: '/masterdata/categories',
    iconComponent: { name: 'cil-sitemap' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  /*  {
      name: 'PLANS',
      url: '/masterdata/plans',
      iconComponent: { name: 'cil-sitemap' }
    },*/
  {
    title: true,
    name: 'INVENTORY',
    attributes: [
      {
        roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
      },
    ],
  },
  {
    name: 'WAREHOUSES',
    url: 'inventory',
    iconComponent: { name: 'cil-calculator' },
    attributes: [
      {
        roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
      },
    ],
    children: [
      {
        name: 'List of warehouses',
        url: 'inventory/warehouses',
        attributes: [
          {
            roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
          },
        ],
      },
      {
        name: 'Add a warehouse',
        url: 'inventory/warehouses/create',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },

  {
    title: true,
    name: 'ORDER',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'ORDERS',
    url: 'orders',
    iconComponent: { name: 'cil-basket' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Toutes les commandes',
        url: '/orders/list/ALL',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'A livrer',
        url: '/orders/list/TO_DELIVER',
        attributes: [
          {
            roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
          },
        ],
      },
      {
        name: 'Configuration de La Livraison',
        url: '/orders/delivery-date-configs',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Affectation des Configuration',
        url: '/orders/assignment-configs',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Frais de livraison',
        url: '/orders/cart-rules',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    title: true,
    name: 'ORDER',
    attributes: [
      {
        roles: [RoleEnum.DELIVERY_SUPERVISOR],
      },
    ],
  },
  {
    name: 'ORDERS',
    url: 'orders',
    iconComponent: { name: 'cil-basket' },
    attributes: [
      {
        roles: [RoleEnum.WAREHOUSE_SUPERVISOR],
      },
    ],
    children: [
      {
        name: 'A livrer',
        url: '/orders/list/TO_DELIVER',
        attributes: [
          {
            roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
          },
        ],
      },
    ],
  },
  {
    name: 'TOURS',
    url: '/tours',
    iconComponent: { name: 'cil-circle' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      // {
      //   name: 'Liste des tours',
      //   url: '/tours',
      //   attributes: [
      //     {
      //       roles: [RoleEnum.ROOT]
      //     }
      //   ]
      // },
      {
        name: 'Dernières Tournées',
        url: '/tours/last-tour',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    title: true,
    name: 'LIVRAISON',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'VERSEMENTS',
    url: 'delivery',
    iconComponent: { name: 'cil-money' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'List of cash deposits',
        url: 'delivery/cashdeposits',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'List of cash plus accounts',
        url: 'delivery/cashdeposits/accounts',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'CHARGES',
    url: 'delivery',
    iconComponent: { name: 'cil-money' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'List of fees',
        url: 'delivery/fees',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'LES PAIEMENTS',
    url: 'delivery',
    iconComponent: { name: 'cil-money' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'List des paiements',
        url: 'delivery/payments',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    title: true,
    name: 'LOGISTIQUE',
    attributes: [
      {
        roles: [
          RoleEnum.ROOT,
          RoleEnum.DELIVERY_SUPERVISOR,
          RoleEnum.WAREHOUSE_SUPERVISOR,
        ],
      },
    ],
  },
  {
    name: 'DASHBOARD DELIVERY',
    url: '/logistic/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    attributes: [
      {
        roles: [RoleEnum.ROOT, RoleEnum.WAREHOUSE_SUPERVISOR],
      },
    ],
  },
  {
    name: 'Tournées',
    url: '/logistic/delivery/tours',
    iconComponent: { name: 'cil-truck' },
    attributes: [
      {
        roles: [
          RoleEnum.ROOT,
          RoleEnum.DELIVERY_SUPERVISOR,
          RoleEnum.WAREHOUSE_SUPERVISOR,
        ],
      },
    ],
  },
  {
    title: true,
    name: 'SUPPORT',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'BOARD',
    url: '/support',
    iconComponent: { name: 'cilXCircle' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    title: true,
    name: 'Wallet',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'Les partenaires',
    url: '/wallet/partners',
    iconComponent: { name: 'cilXCircle' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  // {
  //   title: true,
  //   name: 'CASH PLUS'
  // },
  // {
  //   name: 'ACCOUNTS',
  //   url: 'account',
  //   iconComponent: { name: 'cil-money' },
  //   children: [
  //     {
  //       name: 'List of cash plus accounts',
  //       url: 'cash-plus/accounts',
  //     },
  //   ]
  // },
  {
    title: true,
    name: 'ADMIN',
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
  },
  {
    name: 'USERS',
    url: '/users',
    iconComponent: { name: 'cil-people' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Liste des utilisateurs',
        url: '/users/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Ajouter un utilisateur',
        url: '/users/create',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'ENVIRONMENTS',
    url: '/environments',
    iconComponent: { name: 'cil-center-focus' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Liste des otpCodes',
        url: '/environments/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'VISITES',
    url: '/visits',
    iconComponent: { name: 'cilXCircle' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Liste des visites',
        url: '/visits/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'NOTIFICATIONS',
    url: '/notifications',
    iconComponent: { name: 'cil-bell-exclamation' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Liste des notifications',
        url: '/notifications/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
      {
        name: 'Publier une notification',
        url: '/notifications/create',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
  {
    name: 'VERSIONS',
    url: '/versions',
    iconComponent: { name: '' },
    attributes: [
      {
        roles: [RoleEnum.ROOT],
      },
    ],
    children: [
      {
        name: 'Liste des versions',
        url: '/versions/list',
        attributes: [
          {
            roles: [RoleEnum.ROOT],
          },
        ],
      },
    ],
  },
];
